.root {
  height: calc(100% - 48px);
  :global {
    .ant-card ant-card-bordered {
      height: 100%;
    }
    .ant-card-body {
      height: 100%;
    }
    .ant-tabs-content {
      height: 100%;
    }
  }
}
