.root {
  height: 100%;
  :global {
    .ant-layout-header {
      z-index: 1;
      padding: 0;
      height: 48px;
      line-height: 48px;
      background-color: #fff;
    }
    .ant-form-item {
      font-size: 16px;
      margin-bottom: 0;
      height: 68px;
      align-items: center;
    }

    .ant-layout .ant-layout-sider {
      background-color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .logo {
      position: relative;
      float: left;
      width: 100%;
      height: 64px;
      background-size: 50% 32px;
    }

    .logo img {
      position: absolute;
      width: 104px;
      height: 24px;
      top: 50%;
      line-height: 64px;
      left: 35%;
      transform: translate(-50%, -50%);
    }

    .logo .anticon-menu-unfold {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      right: 5%;
      font-size: 16px;
      // color: #fff;
    }

    .logo .anticon-menu-fold {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      // color: #fff;
    }

    .ant-layout-sider-collapsed {
      background: url(../../assets/u26.png);
    }

    .left-title {
      font-size: 16px;
      color: #313131;
      margin: 0px 68px 0px 24px;
      font-weight: 700;
    }

    .ant-header-right img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    .ant-header-right {
      float: right;
      margin-right: 23px;
    }

    .ant-header-right a {
      margin-right: 40px;
    }

    .ant-header-right .sys-pepo {
      margin: 0px 8px;
    }

    .ant-header-right .setting {
      margin: 0px 16px;
    }

    .ant-menu-root span {
      color: #fff;
      font-size: 16px;
    }

    .ant-menu {
      background-color: transparent;
    }

    .ant-menu svg {
      fill: #fff;
      font-size: 16px;
    }

    .ant-menu-title-content a {
      color: #fff;
      font-size: 16px;
    }

    .ant-menu-submenu-title i {
      color: #fff;
      font-size: 16px;
    }

    .ant-menu-item::after {
      border-right: 0px;
    }

    .ant-menu-submenu-popup {
      width: 176px;
      height: 334px;
      background: #44a0cf !important;
      box-shadow: 0px 2px 4px 1px rgba(149, 149, 149, 0.25);
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
    }

    .ant-menu-submenu-popup > .ant-menu {
      background-color: red;
    }

    .ant-menu-submenu > .ant-menu::before {
      background-color: red;
    }

    .mg_top {
      margin-top: 48px;
    }

    .ant-modal-footer {
      text-align: center;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background: rgba(255, 255, 255, 0.4);
    }
    .ant-menu-item-active {
      background: rgba(255, 255, 255, 0.4) !important;
      color: #fff !important;
    }
  }
}
