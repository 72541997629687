*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  font-family: '微软雅黑', 'MicrosoftYaHei';
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

/* 头部 */
.header {
  position: relative;
  width: 100%;
  height: 320px;
  overflow: hidden;
}

.header .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.header h2 {
  position: relative;
  margin-top: 150px;
  font-size: 50px;
  color: #ffffff;
  letter-spacing: 3px;
  text-align: center;
  z-index: 2;
}

.header-con {
  display: flex;
  align-items: center;
  position: absolute;
  top: 28px;
  left: 50%;
  width: 80%;
  height: 45px;
  margin: 0 auto;
  transform: translateX(-50%);
  z-index: 2;
}

.logo {
  flex: 0 0 211px;
  width: 211px;
  height: 45px;
}

.nav {
  flex: auto;
  list-style: none;
  font-size: 14px;
  color: #ffffff;
  text-align: right;
}

.nav li {
  display: inline-block;
}

.nav a {
  padding: 0 18px;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
}

.btn-login {
  width: 88px;
  height: 28px;
  line-height: 28px;
  margin-left: 50px;
  text-align: center;
  font-size: 12px;
  color: #000000;
  background: #ffffff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 14px;
}

.menu {
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: #f2f6fc;
  font-size: 12px;
  color: #303133;
}

.menu-con {
  width: 1200px;
  margin: 0 auto;
}

.menu .on {
  color: #1989fa;
}

.menu a {
  padding: 0 10px;
  color: #999;
}

.about {
  width: 100%;
  padding: 188px 0 346px;
}

.about p {
  line-height: 50px;
  font-size: 24px;
  color: #303133;
  text-align: center;
}

.law {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 0;
}

.law h1 {
  padding: 60px 0;
  font-size: 24px;
  color: #303133;
  text-align: center;
}

.law a {
  color: #1989fa;
}

.law h2 {
  font-size: 14px;
  color: #000000;
}

.law p {
  line-height: 19px;
  margin-bottom: 16px;
  font-size: 14px;
}

.contact h2 {
  padding: 60px 0 120px;
  text-align: center;
  font-size: 24px;
  font-weight: 200;
  color: #303133;
}

.contact .list {
  display: flex;
  justify-content: space-around;
  width: 1200px;
  margin: 0 auto 275px;
}

.way {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.way .txt1 {
  margin-top: 40px;
  font-size: 20px;
  color: #303133;
}

.way .txt2 {
  margin-top: 20px;
  font-size: 14px;
  color: #303133;
}

.footer {
  width: 100%;
  height: 70px;
  line-height: 70px;
  background: #f2f6fc;
  font-size: 12px;
  color: #606266;
  text-align: center;
}

.footer a {
  color: #606266;
  padding: 0 8px;
}

.footer a:hover {
  color: #1989fa;
}

.footer .code {
  padding-left: 60px;
}

@media only screen and (max-device-width: 667px) {
  .header-con {
    top: 10px;
    width: 98%;
  }

  .logo {
    flex: 0 0 90px;
    width: 90px;
    height: auto;
  }

  .nav {
    font-size: 12px;
    text-align: left;
    margin-left: 6px;
  }

  .nav a {
    font-size: 12px;
    padding: 0;
  }

  .btn-login {
    margin-left: 0;
    width: 60px;
  }

  .header {
    height: 200px;
  }

  .header h2 {
    margin-top: 100px;
    font-size: 23px;
  }

  .contact h2 {
    font-size: 16px;
    padding-bottom: 30px;
  }

  .contact .list {
    width: 100%;
  }

  .way img {
    height: 32px;
  }

  .way .txt1 {
    font-size: 12px;
  }

  .way .txt2 {
    font-size: 12px;
  }

  .law {
    width: 100%;
    padding: 10px;
  }

  .law h1 {
    font-size: 16px;
    padding: 20px 10px;
  }

  .swiper-slide .title {
    font-size: 30px;
  }

  .product-con {
    width: 100%;
  }

  .product-con .tr {
    display: block;
    margin-bottom: 30px;
  }

  .product-con .tr img {
    float: left;
    width: 20%;
    margin: 0;
    margin-left: 5%;
    height: auto;
  }

  .product-txt {
    float: right;
    width: 70%;
    margin: 0;
    margin-left: 5%;
    font-size: 12px;
  }

  .product-txt p {
    padding-right: 10px;
  }

  .about {
    width: 100%;
    padding: 50px 0 200px;
  }

  .about p {
    font-size: 16px;
  }

  .about-con {
    width: 100%;
  }

  .about-con img {
    width: 100px;
  }

  .about-info {
    width: 250px;
    height: auto;
    padding: 15px;
  }

  .about-info .btn-join {
    margin-top: 20px;
  }

  .footer {
    height: auto;
    line-height: 40px;
  }

  .footer a {
    padding: 0;
  }

  .footer .code {
    padding: 0;
    display: block;
    text-align: center;
  }
}
