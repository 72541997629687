.root {
  width: 100%;
  height: 100%;
  background: center/cover url(../../assets/u1.jpg);
  background-size: 100% 100%;

  :global {
    .ant-card-body {
      padding-top: 40px !important;
    }
    .login-container {
      width: 502px;
      height: 444px;
      position: absolute;
      left: 75%;
      top: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 50px rgb(0 0 0 / 10%);
      background: #fff;
      border-radius: 10px;
    }
    .ant-form-item {
      font-size: 16px;
      margin-bottom: 0;
      height: 68px;
    }
    .ant-input {
      width: 284px;
      height: 36px;
    }
    .ant-input-password {
      width: 284px;
      height: 36px;
    }
    .ant-form .ant-form-item .ant-form-item-label {
      max-width: 22%;
      // text-align: left;
      margin-right: 12px;
    }
    .ant-tabs-centered {
      // width: 502px;
      height: 424px;
      // margin: 30px 80px 47px 80px;
      margin-top: 20px;
      font-size: 16px;
      box-sizing: border-box;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      width: 422px;
      height: 1px;
      background: #efefef;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin-left: 40px;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 100px;
    }
    .ant-tabs-top > .ant-tabs-nav {
      margin: 0;
    }
    .ant-input-affix-wrapper > input.ant-input {
      height: 30px;
    }
    .login-container img {
      width: 136px;
      height: 30px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      box-sizing: border-box;
    }
    .login-checkbox-label {
      color: #1890ff;
    }
    .ant-tabs-content-holder {
      margin-top: 40px;
      box-sizing: border-box;
      padding-left: 21px;
    }
    .btn-mar {
      // margin-bottom: 28px;
    }
    .find-password {
      text-align: right;
      padding-right: 65px;
      a {
        color: #016db2;
      }
    }
    .ant-btn-block {
      background: linear-gradient(225deg, #016bb2 0%, #34cade 100%) !important;
      height: 40px;
      width: 352px;
      color: #fff;
      // margin-top: 20px;
      margin-bottom: 0px;
      box-sizing: border-box;
    }
    .ant-card-body {
      padding: 0;
    }
    .ant-col-offset-4 {
      margin-left: 10%;
    }
    .cap-code .sc-bdvvtL {
      height: 32px !important;
      width: 84px;
    }

    // 媒体查询
    @media screen and (max-width: 600px) {
      .login-container {
        width: 318px;
        height: 350px;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        position: absolute;
        left: 50%;
        top: 50%;
      }
      .ant-card-body {
        padding-top: 24px !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
      .ant-form-item {
        font-size: 16px;
        margin-bottom: 0;
        height: 55px;
      }
      .ant-tabs-top > .ant-tabs-nav::before {
        width: 288px;
        height: 1px;
        background: #efefef;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        margin-left: 0px;
      }
      .ant-tabs-centered {
        width: 100%;
        // height: px;
        margin: 0;
        font-size: 16px;
        box-sizing: border-box;
      }
      .ant-tabs-tab + .ant-tabs-tab {
        margin-left: 32px;
      }
      .ant-tabs-top > .ant-tabs-nav {
        margin: 0;
      }
      .login-container img {
        margin-left: auto;
        margin-right: auto;
        margin-top: 4px;
        display: block;
        box-sizing: border-box;
      }
      .login-checkbox-label {
        color: #1890ff;
      }
      .ant-tabs-content-holder {
        margin: 24px -7px 0px 0px;
        box-sizing: border-box;
        padding-left: 0;
      }
      .ant-input {
        // margin-bottom: 15px;
        width: 218px;
        height: 36px;
      }
      .ant-input-password {
        width: 218px;
        height: 36px;
      }
      .ant-input-affix-wrapper > input.ant-input {
        height: 30px;
      }
      .find-password {
        text-align: right;
        padding-right: 0px;
        a {
          color: #016db2;
          font-size: 12px;
        }
      }
      .ant-btn-block {
        background: linear-gradient(
          linear-gradient(225deg, #016bb2 0%, #34cade 100%)
        ) !important;
        height: 40px;
        width: 286px;
        margin-top: 0px;
        color: #fff;
        margin-bottom: 0px;
        box-sizing: border-box;
      }
      .ant-card-body {
        padding: 0;
      }
      .cap-code {
        position: fixed;
        top: 290px !important;
        right: 10px !important;
      }
      .cap-code .sc-bdvvtL {
        height: 32px !important;
        width: 95px;
      }
      .ant-form .ant-form-item .ant-form-item-label {
        max-width: 22%;
        text-align: left;
        margin-right: 8px;
      }

      .ant-form .ant-form-item .ant-form-item-control {
        max-width: 60%;
      }

      .ant-col-offset-4 {
        margin-left: 1%;
      }
      .ant-btn-default {
        border-color: none;
      }
      .ant-form-item-explain-error {
        width: 280px;
      }
    }
  }
}
