.root {
  height: calc(100% - 48px);
  // height: 100%;
  :global {
    .ant-form-item {
      font-size: 16px;
      margin-bottom: 0;
      height: 68px;
    }
  }
}
